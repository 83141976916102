.panel-header-current {
  background-color: #ABEBC6;
}
.panel-header-previous {
  background-color: rgb(236, 186, 22);
}
.profile-card {
  margin-bottom: 20px;
  background-color: #f7f3f3;
}
.ant-badge{
  width: 30%;
  min-width: 50px;
}