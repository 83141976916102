.ant-input {
  border: 1px solid #fff;
  padding-top: 5px;
  height: 47px
}
.form-control{
  padding: 0 !important
}
.customAlert {
  box-sizing: border-box;
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  word-wrap: break-word;
  border-radius: 4px;
  padding: 8px 15px;
}
.green {
  background-color: #b7eb8f;
}
.red {
  background-color: #cc3e3e;
  color: #fff;
}
.black {
  background-color: #000;
}

.cuzmur {
  border: 0px !important;
}
.cuzmur:focus, .cuzmur:active {
  box-shadow: 0 0 0px #01b6f5 !important;
}
.form-control {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.loaderrr{
  position: fixed;
  z-index: 2500;
  width: 50%;
  max-width: 200px;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

/* ======================= Dashboard styles ========================= */
.nav-pills .nav-link.active{
  background-color: #d8b66d !important;
}