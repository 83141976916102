$black: #222;

*, *:before, *:after {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  font-family: helvetica;
  position: relative;
  font-size: 100%;
  /* background: #D1913C;
  background: -webkit-linear-gradient(to right, #FFD194, #D1913C);
  background: linear-gradient(to right, #FFD194, #D1913C); */
}

.gallery-container {
  padding: .9375rem 0;
}

.gallery-container h1 {
  margin: 2rem 0;
  padding: 0;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 6.5vw;
  font-weight: lighter;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;

  img {
    width: 100%;
    border: 5px solid #fff;
  }
}

.gallery-grid div {
  position: relative;
  cursor: pointer;
  border: 1px solid $black;

  &:before, &:after {
    transition: .3s opacity ease;
    opacity: 0;
  }

  &:after {
    content: '\02194';
    font-size: 80px;
    position: absolute;
    transform: translate3d(-50%, -50%, 0) rotate(-45deg);
    color: #fff;
    left: 50%;
    top: 50%;
    display: block;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 4px;
    left: 0;
    right: 0;
    background: rgba(#222, 0.5);
    display: block;
  }

  &:hover {
    &:before, &:after {
      opacity: 1;
      transition: .3s opacity ease;
    }
  }
}

.imageModal {
  position: fixed;
  z-index: 10;
  width: 50%;
  max-width: 800px;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);

  img {
    width: 100%;
    border: 5px solid #fff;
  }
}

.modal-overlay {
  position: fixed;
  z-index: 1;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
}

.modal-body a {
  position: absolute;
  display: inline;
  color: $black;
  text-decoration: none;
  line-height: 36px;
  font-size: 30px;
  font-weight: lighter;
  background: #fff;
  border-radius: 5px;
  height: 40px;
  width: 40px;
  text-align: center;
}

.modal-body .modal-close {
  right: 0;
  top: 0;
  border-radius: 0 0 0 5px;
}

.modal-body .modal-next,
.modal-body .modal-prev {
  right: 0;
  top: calc(50% - 25px);
  border-radius: 5px 0 0 5px;
  height: 50px;
  line-height: 40px;
  font-size: 60px;
}

.modal-body .modal-prev {
  left: 0;
  right: auto;
  border-radius: 0 5px 5px 0;
}

.modal-body {
  position: relative;
}

.ReactGridGallery_tile-bottom-bar {

}

.ReactGridGallery_tile-viewport {
  height: 210px !important;
}
