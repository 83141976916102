.coach-values {
    border: 1px solid rgba(216, 182, 109, 0.75) !important;
}

.form-control-alternative {
    margin: 5px 0px;
}

.ant-tag {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    min-width: 60px;
    text-align: center;
}

.ant-modal-wrap .bg-secondary.shadow.card {
    box-shadow: none !important;
    padding: 0 !important;
}

.ant-table-tbody > tr > td, .ant-table-thead > tr > th {
    white-space: nowrap;
}

.card-header {
    padding: 1.25rem 1.5rem;
}

.ant-table-pagination.ant-pagination {
    margin: 15px;
}

.ant-table-body {
    overflow-x: auto;
}


.bg-gradient-info {
    background: #d8b66d !important;
}

.navbar-vertical.navbar-expand-md.fixed-left {
    background-color: #000 !important;
}

.navbar-vertical.navbar-expand-md .navbar-nav .nav-link.active:before {
    border-left: 2px solid #fff;
}

.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link,
.text-primary {
    color: #fff !important;
}

.card-header h3 {
    font-size: 1.4rem;
    color: #d8b66d !important;
}

.navbar-collapse .collapse-brand img {
    max-width: 100%;
    height: auto;
}


.card {
    border: 0;
}

.card-header {
    background-color: #000;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle,
.ant-btn-primary,
.ant-btn-primary.active, .ant-btn-primary:active {
    color: #fff;
    background-color: #d8b66d !important;
    border-color: #d8b66d !important;
}

.navbar-vertical.navbar-expand-md .navbar-nav .nav-link.active:before,
.ant-pagination-item-active {
    border-color: #d8b66d !important;
}

a,
a:hover {
    color: #d8b66d;
}

.ant-btn-primary:focus, .ant-btn-primary:hover,
.btn-primary, .btn-primary:hover {
    background-color: #000;
}

.ant-modal-wrap .bg-secondary.shadow.card {
    box-shadow: none !important;
    padding: 0 !important;
    background-color: #fff !important;
}


.ant-modal-header {
    color: #d8b66d !important;
    background: #000;
}

.ant-modal-title {
    color: #d8b66d !important;
}

.ant-modal-close-x {
    display: block;
    width: 30px;
    height: 30px;
    font-size: 16px;
    font-style: normal;
    line-height: 56px;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
    background-color: rgba(216, 182, 109, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 12px;
    right: 12px;
    border-radius: 4px;
}

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, .5);
    border-color: transparent;
    background-color: #fff;
    padding: 1px 3px;
    margin-right: 10px;
    border-radius: 3px;
}


.navbar-toggler-icon {
    width: 1em;
    height: 1em;
}

.ds-none {
    display: none;
}

.mb-none {
    max-width: 80% !important;
    margin: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

@media (max-width: 767.98px) {
    .navbar-light .navbar-nav .nav-link,
    .navbar-light .navbar-nav .active > .nav-link,
    .navbar-light .navbar-nav .nav-link.active,
    .navbar-light .navbar-nav .nav-link.show,
    .navbar-light .navbar-nav .show > .nav-link,
    .text-primary {
        color: #000 !important;
    }
}


@media (max-width: 480px) {
    .navbar, .navbar > .container, .navbar > .container-fluid {
        flex-wrap: nowrap;
    }

    .navbar-collapse .navbar-nav .active > .nav-link,
    .navbar-collapse .navbar-nav .nav-link,
    .navbar-collapse .navbar-nav .nav-link.active,
    .navbar-collapse .navbar-nav .nav-link.show,
    .navbar-collapse .navbar-nav .show > .nav-link,
    .navbar-collapse .text-primary {
        color: #000 !important;
    }

    .mb-none {
        display: none;
    }

    .ds-none {
        display: block;
    }
}

hr {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.str-chat__container {
    flex-direction: column;
}

.str-chat-channel {
    max-height: 86vh !important;
}

.messaging.str-chat .str-chat__header-livestream {
    z-index: unset !important;
}

.mContainer {
    text-align: center;
    max-width: 1200px;
    margin: 22px auto;
    height: 65vh;
    overflow-y: scroll;
}

.add-container {
    text-align: center;
    max-width: 1200px;
    margin: 22px auto;
}

form.add-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #eee;
    border-radius: 5px;
    margin-bottom: 20px;
}

form.add-container textarea {
    border: none;
    border-radius: 5px;
    padding: 10px 10px 10px 30px;
    width: 80%;
    font-size: 1.0rem;
}

ul.mContainer {
    list-style: none;
    padding: 0;
    margin: 0 auto;
}

ul.mContainer li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background-color: #eee;
    margin-bottom: 10px;
}

ul.mContainer li s,
ul.mContainer li span.message {
    font-size: 1.0rem;
    font-weight: normal;
    color: #333;
    text-align: left;
    padding-left: 20px;
    max-width: 85%;
}

ul.mContainer li button {
    font-size: 1.0rem;
    font-weight: normal;
    color: #fff;
    cursor: pointer;
}

.macros-sync {
    background-color: #000c17 !important;
    border-color: #DC3535 !important;
    margin-left: 20px;
}
